<template>
  <div class="p-2">
    <Toast />
    <!-- <div class="w-full fixed top-0 left-0 py-3 px-4 z-5 bg-white shadow-2"> -->
      <!-- Topbar -->
      <!-- <div class="flex justify-content-between align-items-center">
        <span class="text-2xl font-bold">盤點</span>
      </div>
      <div class="fixed bottom-8 right-4">
        <div class="relative">
          <Button @click="displayListDialog = true" label="" icon="pi pi-file" iconPos="top" class="p-button-purpply" />
        </div>
      </div>
    </div> -->

    <div class="flex justify-content-between align-items-center">

      <Dropdown class="mb-2" v-model="selectedProduct" :options="productOptions" optionLabel="name"
        placeholder="選擇盤點商品" />
      庫存總量: {{ summary.quantity }} {{ summary.uom }}
    </div>

    <DataTable class="mb-6" :value="currentProductLots" responsiveLayout="scroll">
      <!-- <Column field="id" header="ID"></Column> -->
      <!-- <Column field="name" header="批號"></Column> -->
      <Column field="product_consumed" header="">
        <template #body="{data}">
          {{ data.quantity }} {{ data.uom }}
        </template>
      </Column>
      <Column field="date" header="盤點日期" dataType="date">
        <template #body="{data}">
          {{ formatDate(data.create_datetime) }}
        </template>
      </Column>
      <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button type="button" class="p-button-purpply" icon="pi pi-pencil"
            @click="preTakeInventory(slotProps.data)"></Button>
        </template>
      </Column>
      <template #empty>
        無資料. 選擇盤點商品
      </template>
    </DataTable>

    <!-- 盤點數量 Dialog -->
    <Dialog header="" v-model:visible="displaySelectionDialog" :dismissableMask="true"
      :breakpoints="{ '960px': '96vw' }" :style="{ width: '30vw' }" :modal="true">
      <div class="p-inputgroup mt-2">
        <InputText placeholder="輸入盤點數量" mode="decimal" v-model="selectedInventory.quantity" />
        <span class="p-inputgroup-addon">{{ selectedInventory.uom }}</span>
      </div>
      <div class="mt-4">
        <Button class="p-button-secondary p-button w-full" label="更新庫存數量"
          :disabled="selectedInventory.quantity === undefined" @click="takeInventory" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios"
import { reactive } from "vue"

export default {
  data() {
    return {
      products: [],
      selectedProduct: { name: 'Foo', quantity: 0 },
      selectedInventory: reactive({}),
      purchaseList: reactive([]),
      displaySelectionDialog: false,
      displayListDialog: false,
    }
  },
  computed: {
    productOptions() {
      return this.products.map((prod) => {
        return { id: prod.product_id, name: prod.product_name }
      })
    },
    currentProductLots() {
      let selectedProductData = this.products.find((prod) => prod.product_id == this.selectedProduct.id)
      console.log('currentProductLots', selectedProductData)
      if (selectedProductData === undefined) {
        return []
      } else {
        if (selectedProductData.lots.length > 0) {
        selectedProductData.lots.sort((a, b) => b.id - a.id)
        console.log('selectedProductData.lots', selectedProductData.lots)
        return [selectedProductData.lots[0]]
        } else {
          return []
        }
      }
    },
    summary() {
      console.log('summary', this.currentProductLots)
      if (this.currentProductLots.length > 0) {
        const initQuantity = 0
        const sumWithInitial = this.currentProductLots.reduce(
          (preVal, curVal) => {
            // console.log(preVal, curVal)
            return preVal + curVal.quantity
          },
          initQuantity
        )
        return {
          quantity: sumWithInitial,
          uom: this.currentProductLots[0].uom
        }
      } else {
        return {
          quantity: 0,
          uom: 'mL'
        }
      }
    },
  },
  methods: {
    preTakeInventory(inv) {
      this.selectedInventory = { ...inv }
      this.displaySelectionDialog = true
    },
    async getInventoryData() {
      let resp = await axios("/api/get_inventory_products", {
        method: "GET",
        withCredentials: true,
        params: {
          token: "0000",
        },
      })

      this.products = resp.data.products.filter((prod) => prod.category == '茶葉').sort((a, b) => a.product_id - b.product_id)
    },
    async takeInventory() {
      let resp = await axios("/api/take_inventory", {
        method: "POST",
        withCredentials: true,
        params: {
          token: "0000",
        },
        data: {
          products: [
            {
              product_id: this.selectedInventory.product_id,
              lot_id: this.selectedInventory.id,
              quantity: parseInt(this.selectedInventory.quantity),
            }
          ]
        }
      })

      if (resp.data.result) {
        this.$toast.add({ severity: 'success', summary: '成功', detail: '已更新庫存', life: 2000 })
      }

      this.displaySelectionDialog = false
      await this.getInventoryData()
    },
    formatDate(value) {
      let dateObj = new Date(Date.parse(value))
      return dateObj.toLocaleDateString('zh-Hans', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  },
  async created() {
    await this.getInventoryData()
  },
}
</script>

<style lang="scss" scoped>
.bottom-8 {
  bottom: 5rem;
}

.right-4 {
  right: 1.55rem;
}
</style>